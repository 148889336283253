import { useCallback, useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import CloseBtn from '../ui/CloseBtn'

const BoardForm = ({
	type = 'list',
	inputData = '',
	close,
	submit,
	btnText,
	loading
}) => {
	const [touched, setTouched] = useState(false)
	const [data, setData] = useState(inputData)
	const ref = useRef()

	const closeFormOnOutsideClick = useCallback(
		(evt) => {
			//console.log(evt)
			if (!evt.target.closest('.board-form')) {
				close()
			}
		},
		[close]
	)

	useEffect(() => {
		ref.current.scrollIntoView({
			behaviour: 'smooth',
			block: 'nearest',
			inline: 'nearest'
		})

		// if a click happens somewhere outside the form closes.
		// mousedown event help close form even during dragging.
		document.body.addEventListener('mousedown', closeFormOnOutsideClick)
		return () =>
			//clean the event listener
			document.body.removeEventListener('mousedown', closeFormOnOutsideClick)
	}, [close, closeFormOnOutsideClick])

	const handleSubmit = (event) => {
		event.preventDefault()
		const newData = data.trim()
		setData(newData)
		if (newData.length >= 5) {
			//don't update if there is no change
			if (inputData === newData) {
				close()
			} else {
				submit(newData)
			}
		}
		setTouched(true)
	}

	return (
		<Form ref={ref} onSubmit={handleSubmit} className="w-100 board-form my-1">
			{type === 'list' ? (
				<Form.Group className="mb-3">
					<Form.Control
						autoComplete="off"
						type="text"
						name="data"
						placeholder="Enter list title..."
						className="mb-2"
						autoFocus
						isInvalid={touched && data.trim().length < 5}
						value={data}
						onChange={(e) => {
							setData(e.target.value)
						}}
					/>
					<Form.Control.Feedback type="invalid">
						Min 5 character required.
					</Form.Control.Feedback>
				</Form.Group>
			) : (
				<Form.Group className="mb-1">
					<Form.Control
						as="textarea"
						name="data"
						placeholder="Enter card details"
						style={{ height: '100px' }}
						className="mb-2"
						autoFocus
						isInvalid={touched && data.trim().length < 5}
						value={data}
						onChange={(e) => {
							setData(e.target.value)
						}}
					/>
					<Form.Control.Feedback type="invalid">
						Min 5 character required.
					</Form.Control.Feedback>
				</Form.Group>
			)}

			<div className="d-flex align-items-center gap-2">
				<Button variant="primary" type="submit" size="sm" disabled={loading}>
					{loading && (
						<>
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
							<span className="visually-hidden">Loading...</span>{' '}
						</>
					)}
					{btnText}
				</Button>
				<CloseBtn onClose={close} />
			</div>
		</Form>
	)
}

export default BoardForm
