import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { BoardContext } from '../../views/Board'
import BoardForm from './BoardForm'
import { v4 as uuidv4 } from 'uuid'
import { AppContext } from '../../context/AppContext'

const AddCard = ({ listId }) => {
	const { board } = useContext(BoardContext)
	const { setError } = useContext(AppContext)
	const [showForm, setShowForm] = useState(false)
	const [loading, setLoading] = useState(false)

	const addCard = async (data) => {
		for (let list of board.lists) {
			if (list.id === listId) {
				setLoading(true)
				list.cards.push({
					id: uuidv4(),
					data
				})
				try {
					await board.save()
					setShowForm(false)
				} catch (error) {
					console.log('Add Card Error.', error)
					setError({
						type: 'danger',
						title: 'Add Card Error.',
						message: 'Failed to add card.'
					})
				} finally {
					setLoading(false)
				}
			}
		}
	}

	return (
		<div className="rounded-bottom bg-secondary bg-opacity-25">
			{!showForm ? (
				<div
					className="px-3 py-2 cursor-pointer"
					onMouseDown={() => setTimeout(() => setShowForm(true), 0)}>
					<FontAwesomeIcon icon={faPlus} />{' '}
					<span className="ms-1">Add a Card</span>
				</div>
			) : (
				<div className="p-2 rounded">
					<BoardForm
						type="card"
						close={() => setShowForm(false)}
						btnText="Add Card"
						loading={loading}
						submit={addCard}
					/>
				</div>
			)}
		</div>
	)
}

export default AddCard
