import { useState } from 'react'
import { Button, Modal, Form, Spinner } from 'react-bootstrap'

const DeleteBoardModal = ({
	name,
	deleteBoard,
	show,
	loading,
	handleClose
}) => {
	const [input, setInput] = useState('')
	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Are you absolutely sure?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						This action <strong>cannot</strong> be undone. This will permanently
						delete the board <strong>{name}.</strong>
					</div>
					<Form.Control
						type="text"
						placeholder="Enter name of board."
						className="my-3"
						value={input}
						onChange={(e) => setInput(e.target.value)}
					/>
					<Button
						variant="danger"
						onClick={deleteBoard}
						className="w-100"
						disabled={input !== name || loading}>
						{loading && (
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								<span className="visually-hidden">Loading...</span>{' '}
							</>
						)}
						I understand the consequences, delete this board.
					</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default DeleteBoardModal
