import AddBoard from '../components/boards/AddBoard'
import ExistingBoard from '../components/boards/ExistingBoard'

const Boards = () => {
	return (
		<div className="container">
			<h3>Your Boards</h3>
			<div className="boards-cards py-3">
				<AddBoard />
				<ExistingBoard />
			</div>
		</div>
	)
}

export default Boards
