import { useState } from 'react'
import { Button, Modal, Form, Spinner } from 'react-bootstrap'

const AddOrUpdateBoardModal = ({
	show,
	handleClose,
	action = 'add',
	submitForm,
	boardName,
	bgColor,
	loading
}) => {
	const [name, setName] = useState(boardName)
	const [backgroundColor, setBackgroundColor] = useState(bgColor)
	const [touched, setTouched] = useState(false)

	const handleSubmit = (event) => {
		event.preventDefault()
		setName(name.trim())
		if (name.trim().length >= 3) {
			submitForm({ name, backgroundColor })
		}
		setTouched(true)
	}

	return (
		<Modal show={show} onHide={handleClose} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>
					{action === 'add' ? 'Create New Board' : 'Update Board'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3">
						<Form.Label htmlFor="boardName">Board Name</Form.Label>
						<Form.Control
							isInvalid={touched && name.trim().length < 3}
							autoComplete="off"
							id="boardName"
							type="text"
							placeholder="Enter name"
							value={name}
							onChange={(e) => {
								setName(e.target.value)
							}}
							autoFocus
						/>
						<Form.Control.Feedback type="invalid">
							Name must be atleast 3 characters long.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label htmlFor="colorPicker">Color picker</Form.Label>
						<Form.Control
							className="w-100"
							type="color"
							id="colorPicker"
							value={backgroundColor}
							onChange={(e) => {
								setBackgroundColor(e.target.value)
							}}
							title="Choose your color"
						/>
					</Form.Group>
					<div className="d-flex justify-content-end">
						<Button variant="primary" type="submit" disabled={loading}>
							{loading && (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									<span className="visually-hidden">Loading...</span>{' '}
								</>
							)}
							{action === 'add' ? 'Create' : 'Update'}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	)
}

export default AddOrUpdateBoardModal
