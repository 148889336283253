import { Navbar, Image, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrello } from '@fortawesome/free-brands-svg-icons'
import { faCaretDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { logout } from '../../firebase/auth'
import { useContext, useMemo } from 'react'
import { AppContext } from '../../context/AppContext'
import { CleanFirebaseAuthErrorMessage } from '../../utils'
import { Link } from 'react-router-dom'
import { User } from '../../firebase/models'

const Appbar = () => {
	const { loginData, setError, isAuthenticated } = useContext(AppContext)
	const user = useMemo(() => {
		const currUser = new User()
		if (loginData) {
			currUser.id = loginData.uid
			currUser.data = { name: loginData.displayName, ...loginData }
		}
		return currUser
	}, [loginData])

	const signout = async () => {
		try {
			await logout()
		} catch (error) {
			console.error('logout :', error.message)
			setError({
				type: 'danger',
				title: 'Failed to Logout',
				message: CleanFirebaseAuthErrorMessage(error.code)
			})
		}
	}

	const popover = (
		<Popover
			id="user-info-popover"
			className="shadow-lg"
			style={{ width: '15rem' }}>
			<Popover.Header as="h3" className="text-center">
				<strong>Welcome</strong>
				<p className="mb-0 text-truncate">{user?.name}</p>
			</Popover.Header>
			<Popover.Body className="d-flex justify-content-center">
				<Button className="w-100" variant="danger" onClick={signout}>
					<FontAwesomeIcon icon={faSignOutAlt} size="lg" className="me-2" />
					Logout
				</Button>
			</Popover.Body>
		</Popover>
	)

	return (
		<Navbar className="shadow" fixed="top" bg="primary" variant="dark">
			<div className="my-container d-flex justify-content-between align-items-center">
				<Link to="/">
					<Navbar.Brand disabled={!isAuthenticated}>
						<FontAwesomeIcon icon={faTrello} size="lg" /> Simple Trello
					</Navbar.Brand>
				</Link>
				{isAuthenticated && (
					<div className="px-3">
						<OverlayTrigger
							trigger="click"
							placement="auto"
							overlay={popover}
							id="user-info-popover-trigger"
							rootClose
							rootCloseEvent="mousedown">
							<div>
								<Image
									style={{ height: '2.75rem' }}
									src={user.photoURL}
									roundedCircle
									referrerPolicy="no-referrer"
								/>
								<FontAwesomeIcon
									icon={faCaretDown}
									size="lg"
									className="ms-2 text-light"
								/>
							</div>
						</OverlayTrigger>
					</div>
				)}
			</div>
		</Navbar>
	)
}

export default Appbar
