import {
	useContext,
	useEffect,
	useState,
	useReducer,
	createContext
} from 'react'
import { useParams } from 'react-router-dom'
import BoardList from '../components/board/BoardList'
import { Board as BoardCollection } from '../firebase/models'
import { Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import { serverTimestamp } from 'firebase/firestore'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'

export const BoardContext = createContext()

const Board = () => {
	const { id } = useParams()
	const [pageError, setPageError] = useState(null)
	const [loading, setLoading] = useState(true)
	const { setBgColor } = useContext(AppContext)
	const [board] = useState(new BoardCollection(id))
	//class instance as state don't re-render on value change that's why using forceUpdate.
	const [, forceUpdate] = useReducer((x) => x + 1, 0)
	const [docExists, setDocExists] = useState(false)

	useEffect(() => {
		const unsubscribe = onSnapshot(
			doc(db, 'boards', id),
			(doc) => {
				if (doc.data()) {
					const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
					//console.log(source, 'board realtime listner data: ', doc.data())
					if (source === 'Server') {
						board.data = doc.data()
						setLoading(false)
						setDocExists(true)
						setBgColor(board.bgColor)
						forceUpdate()
					}
				} else {
					setPageError(`Board with above id doesn't exist.`)
				}
			},
			(error) => {
				console.log('Board Fetch Error : ', error)
				setPageError('Failed to fetch Baord.')
				setLoading(false)
			}
		)

		return () => {
			unsubscribe()
			setBgColor('')
		}
	}, [board, setBgColor, id])

	//update the last openned time
	useEffect(() => {
		if (docExists) {
			board.opennedAt = serverTimestamp()
			board
				.save()
				.then()
				.catch((error) => {
					console.log('Failed to update opennedAt time', error)
				})
		}
	}, [docExists, board])

	return (
		<div className="board">
			{pageError ? (
				<h3 className="text-danger text-center">
					<span>{pageError}</span>
				</h3>
			) : loading ? (
				<div className="d-flex h-100 justify-content-center align-items-center">
					<Spinner animation="border" variant="primary" />
				</div>
			) : (
				<>
					<h4 className="d-inline-block text-truncate w-100 board-title text-white">
						{board.name}
					</h4>
					<BoardContext.Provider value={{ board }}>
						<BoardList />
					</BoardContext.Provider>
				</>
			)}
		</div>
	)
}

export default Board
