import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { BoardContext } from '../../views/Board'
import BoardForm from './BoardForm'

const AddList = () => {
	const { board } = useContext(BoardContext)
	const { setError } = useContext(AppContext)
	const [showForm, setShowForm] = useState(false)
	const [loading, setLoading] = useState(false)

	const addList = async (data) => {
		setLoading(true)
		try {
			await board.addList(data)
			setShowForm(false)
		} catch (error) {
			console.log('Add List Error.', error)
			setError({
				type: 'danger',
				title: 'Add List Error.',
				message: 'Failed to add list.'
			})
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="add-list shadow-sm bg-warning rounded">
			{!showForm ? (
				<div
					className="p-2 rounded cursor-pointer"
					onClick={() => setShowForm(true)}>
					<FontAwesomeIcon icon={faPlus} />{' '}
					<span className="ms-1">Add a new List</span>
				</div>
			) : (
				<div className="bg-opacity-25 p-2">
					<BoardForm
						close={() => setShowForm(false)}
						btnText="Add List"
						loading={loading}
						submit={addList}
					/>
				</div>
			)}
		</div>
	)
}

export default AddList
