import { useContext, useState } from 'react'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Popover,
	Button,
	OverlayTrigger,
	Spinner,
	Tooltip
} from 'react-bootstrap'
import { faPenAlt } from '@fortawesome/free-solid-svg-icons'
import BoardForm from './BoardForm'
import { BoardContext } from '../../views/Board'
import { AppContext } from '../../context/AppContext'

const BoardListHeader = ({ id, title }) => {
	const { board } = useContext(BoardContext)
	const { setError } = useContext(AppContext)
	const [showPopover, setShowPopover] = useState(false)
	const [showUpdateForm, setShowUpdateForm] = useState(false)
	const [loading, setLoading] = useState(false)

	const togglePopover = () => {
		setShowPopover(!showPopover)
	}

	const updateList = async (title) => {
		const lists = board.lists
		for (let list of lists) {
			if (list.id === id) {
				setLoading(true)
				list.title = title
				try {
					await board.save()
					setShowUpdateForm(false)
				} catch (error) {
					console.log('Update List Title Error.', error)
					setError({
						type: 'danger',
						title: 'Update List Title Error.',
						message: 'Failed to update list title.'
					})
				} finally {
					setLoading(false)
				}
			}
		}
	}

	const deleteList = async () => {
		setLoading(true)
		board.lists = board.lists.filter((list) => list.id !== id)
		await board
			.save()
			.catch((error) => {
				console.log('Delete List Error.', error)
				setError({
					type: 'danger',
					title: 'Delete List Error.',
					message: 'Failed to delete list.'
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const popover = (
		<Popover id="popover-basic">
			<Popover.Body style={{ width: '200px' }}>
				<h5>Delete this List?</h5>
				<div className="d-flex justify-content-end gap-2">
					<Button
						variant="danger"
						size="sm"
						onClick={deleteList}
						disabled={loading}>
						{loading && (
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								<span className="visually-hidden">Loading...</span>{' '}
							</>
						)}
						Confirm
					</Button>
					<Button variant="secondary" size="sm" onClick={togglePopover}>
						Cancel
					</Button>
				</div>
			</Popover.Body>
		</Popover>
	)

	return (
		<div className="board-list__header">
			{!showUpdateForm ? (
				<>
					<OverlayTrigger
						placement="top"
						delay={{ show: 250, hide: 100 }}
						overlay={
							title.length > 25 ? <Tooltip>{title}</Tooltip> : <div></div>
						}>
						<h6 className="text-truncate px-2 mb-0" style={{ width: '90%' }}>
							{title}
						</h6>
					</OverlayTrigger>

					<FontAwesomeIcon
						icon={faPenAlt}
						className="text-primary me-2 cursor-pointer"
						onClick={() => setShowUpdateForm(true)}
					/>
					<OverlayTrigger
						show={showPopover}
						onToggle={togglePopover}
						trigger="click"
						placement="auto"
						overlay={popover}
						id="user-info-popover-trigger"
						rootClose
						rootCloseEvent="mousedown">
						<div>
							<FontAwesomeIcon
								icon={faTrashAlt}
								className="text-danger cursor-pointer"
							/>
						</div>
					</OverlayTrigger>
				</>
			) : (
				<BoardForm
					close={() => setShowUpdateForm(false)}
					loading={loading}
					btnText="Update List"
					inputData={title}
					submit={updateList}
				/>
			)}
		</div>
	)
}

export default BoardListHeader
