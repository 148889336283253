import { useState } from 'react'
import { createContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { useEffect } from 'react'

export const AppContext = createContext()

const AppContextProvider = ({ children }) => {
	const [loginData, setLoginData] = useState(
		JSON.parse(localStorage.getItem('loginData')) || null
	)
	const [error, setError] = useState({ type: '', title: '', message: '' })
	const [bgColor, setBgColor] = useState('')

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setLoginData(user)
				localStorage.setItem('loginData', JSON.stringify(user))
			} else {
				setLoginData(null)
				localStorage.removeItem('loginData')
			}
		})
		return unsubscribe
	}, [])

	return (
		<AppContext.Provider
			value={{
				loginData,
				error,
				setError,
				isAuthenticated: loginData || false,
				bgColor,
				setBgColor
			}}>
			{children}
		</AppContext.Provider>
	)
}

export default AppContextProvider
