import ToastAlert from './components/ui/ToastAlert'
import AppContextProvider from './context/AppContext'
import AppRouter from './routes/AppRouter'

const App = () => {
	return (
		<div className="app">
			<AppContextProvider>
				<ToastAlert />
				<AppRouter />
			</AppContextProvider>
		</div>
	)
}

export default App
