import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPenAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import AddOrUpdateBoardModal from './AddOrUpdateBoardModal'
import { useState, useContext, useEffect } from 'react'
import DeleteBoardModal from './DeleteBoardModal'
import { AppContext } from '../../context/AppContext'

import {
	collection,
	onSnapshot,
	orderBy,
	query,
	where
} from 'firebase/firestore'
import { db } from '../../firebase'
import { Board } from '../../firebase/models'

const ExistingBoard = () => {
	const { isAuthenticated, setError, loginData } = useContext(AppContext)
	const [showUpdateModal, setShowUpdateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [pageLoading, setPageLoading] = useState(false)
	const [boards, setBoards] = useState([])

	//board which is getting edited or deleted
	const [activeBoard, setActiveBoard] = useState(null)

	const history = useHistory()

	useEffect(() => {
		if (!showUpdateModal) setActiveBoard(null)
	}, [showUpdateModal])

	useEffect(() => {
		if (!showDeleteModal) setActiveBoard(null)
	}, [showDeleteModal])

	useEffect(() => {
		if (isAuthenticated) {
			const q = query(
				collection(db, 'boards'),
				where('userId', '==', loginData.uid),
				orderBy('opennedAt', 'desc')
			)
			setPageLoading(true)
			const unsubscribe = onSnapshot(
				q,
				(querySnapshot) => {
					const allBoards = []
					querySnapshot.forEach((doc) => {
						allBoards.push(new Board(doc.id, doc.data()))
					})
					setBoards(allBoards)
					setPageLoading(false)
				},
				(error) => {
					setPageLoading(false)
					console.error('Fetch all board error: ', error.message)
					setError({
						type: 'danger',
						title: 'Fetch all board error: ',
						message: error.message
					})
				}
			)
			return unsubscribe
		}
	}, [isAuthenticated, setError, loginData])

	const handleShowUpdateModal = (e, board) => {
		e.stopPropagation()
		e.preventDefault()
		setActiveBoard(board)
		setShowUpdateModal(true)
	}
	const handleShowDeleteModal = (e, board) => {
		e.stopPropagation()
		e.preventDefault()
		setActiveBoard(board)
		setShowDeleteModal(true)
	}

	const updateBoard = async (data) => {
		setLoading(true)
		activeBoard.name = data.name
		activeBoard.bgColor = data.backgroundColor
		try {
			await activeBoard.save()
			setShowUpdateModal(false)
		} catch (error) {
			console.error('Board update doc: ', error.message)
			setError({
				type: 'danger',
				title: 'Update Board Error',
				message: error.message
			})
		} finally {
			setLoading(false)
		}
	}

	const deleteBoard = async () => {
		setLoading(true)
		try {
			await activeBoard.delete()
			setShowDeleteModal(false)
		} catch (error) {
			setError({
				type: 'danger',
				title: 'Delete Board Error',
				message: error.message
			})
		} finally {
			setLoading(false)
		}
	}

	const openBoard = async (board) => {
		history.push(`/board/${board.id}`)
	}

	return (
		<>
			{boards.map((board) => (
				<div
					key={board.id}
					onClick={() => openBoard(board)}
					className="text-decoration-none">
					<Card
						className="boards-card shadow-sm rounded pb-2 fade-animation-parent"
						style={{ backgroundColor: board.bgColor }}>
						<div className="boards-card__content">
							<strong className="boards-card__title fs-6 p-1">
								{board.name}
							</strong>
						</div>
						<div className="boards-card__actions fade-animation">
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 100 }}
								overlay={<Tooltip>Edit</Tooltip>}>
								<span>
									<FontAwesomeIcon
										className="boards-card__actions__icon text-primary"
										icon={faPenAlt}
										onClick={(e) => handleShowUpdateModal(e, board)}
									/>
								</span>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 100 }}
								overlay={<Tooltip>Delete</Tooltip>}>
								<span>
									<FontAwesomeIcon
										className="boards-card__actions__icon text-danger"
										icon={faTrashAlt}
										onClick={(e) => handleShowDeleteModal(e, board)}
									/>
								</span>
							</OverlayTrigger>
						</div>
					</Card>
				</div>
			))}
			{activeBoard && (
				<AddOrUpdateBoardModal
					show={showUpdateModal}
					handleClose={() => setShowUpdateModal(false)}
					submitForm={updateBoard}
					action="update"
					boardName={activeBoard.name}
					bgColor={activeBoard.bgColor}
					loading={loading}
				/>
			)}
			{activeBoard && (
				<DeleteBoardModal
					show={showDeleteModal}
					handleClose={() => setShowDeleteModal(false)}
					deleteBoard={deleteBoard}
					name={activeBoard.name}
					loading={loading}
				/>
			)}
			{pageLoading && (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner animation="border" variant="primary" />
				</div>
			)}
		</>
	)
}

export default ExistingBoard
