import { useEffect } from 'react'
import { useContext, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { AppContext } from '../../context/AppContext'

const ToastAlert = () => {
	const { error } = useContext(AppContext)
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (error.message) {
			setShow(true)
		}
	}, [error])

	return (
		<Toast
			onClose={() => setShow(false)}
			bg={error.type}
			show={show}
			delay={5000}
			animation={false}
			autohide
			className="position-fixed toast-alert"
			position="top-end">
			<Toast.Header>
				<strong className="me-auto">{error.title}</strong>
			</Toast.Header>
			<Toast.Body>{error.message}</Toast.Body>
		</Toast>
	)
}

export default ToastAlert
