import {
	serverTimestamp,
	doc,
	setDoc,
	collection,
	deleteDoc
} from 'firebase/firestore'
import { User } from '.'
import { db } from '..'
import { v4 as uuidv4 } from 'uuid'

export default class Board {
	#collection = 'boards'
	#id = ''
	#data = {}

	constructor(id = '', data = {}) {
		this.#id = id
		this.#data = data
	}

	save = (action = 'update') => {
		if (action === 'add') {
			this.opennedAt = serverTimestamp()
			this.createdAt = serverTimestamp()
			this.lists = []
			this.userId = User.getId()
		}
		const docRef = this.#id
			? doc(db, this.#collection, this.#id)
			: doc(collection(db, this.#collection))
		return new Promise(async (resolve, reject) => {
			try {
				await setDoc(
					docRef,
					{ ...this.#data, updatedAt: serverTimestamp() },
					{ merge: true }
				)
				resolve('success')
			} catch (error) {
				reject(error)
			}
		})
	}

	delete = () => {
		const docRef = doc(db, this.#collection, this.#id)
		return new Promise(async (resolve, reject) => {
			try {
				await deleteDoc(docRef)
				resolve('sucess')
			} catch (error) {
				reject(error)
			}
		})
	}

	addList = (title) => {
		this.#data.lists.push({
			id: uuidv4(),
			title,
			cards: []
		})
		return this.save()
	}

	get lists() {
		return this.#data.lists || []
	}

	set lists(value = []) {
		this.#data.lists = value
	}

	get id() {
		return this.#id
	}

	set id(value) {
		this.#id = value
	}

	get data() {
		return this.#data
	}

	set data(value) {
		this.#data = value
	}

	get createdAt() {
		return this.#data.createdAt
	}

	set createdAt(value) {
		this.#data.createdAt = value
	}

	get updatedAt() {
		return this.#data.updatedAt
	}

	set updatedAt(value) {
		this.#data.updatedAt = value
	}

	get opennedAt() {
		return this.#data.opennedAt
	}

	set opennedAt(value) {
		this.#data.opennedAt = value
	}

	get name() {
		return this.#data.name
	}

	set name(value) {
		this.#data.name = value
	}

	get bgColor() {
		return this.#data.bgColor
	}

	set bgColor(value) {
		this.#data.bgColor = value
	}

	get userId() {
		return this.#data.userId
	}

	set userId(value) {
		this.#data.userId = value
	}
}
