import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom'
import Login from '../views/Login'
import Appbar from '../components/ui/Appbar'
import Boards from '../views/Boards'
import Board from '../views/Board'
import ProtectedRoute from './ProtectedRoute'
import UnProtectedRoute from './UnProtectedRoute'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'

const AppRouter = () => {
	const { bgColor } = useContext(AppContext)
	return (
		<Router>
			<div style={{ backgroundColor: `${bgColor}` }} className="h-100">
				<Appbar />
				<div className="my-container content">
					<Switch>
						<UnProtectedRoute path="/auth">
							<Login />
						</UnProtectedRoute>
						<ProtectedRoute exact path="/">
							<Boards />
						</ProtectedRoute>
						<ProtectedRoute path="/board/:id">
							<Board />
						</ProtectedRoute>
						<Route path="*">
							<Redirect to="/auth" />
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	)
}

export default AppRouter
