import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
	apiKey: 'AIzaSyAfeQrxvMkYX8VOjhFZJrwUP7qbam21HBA',
	authDomain: 'simple-trello-dfdcf.firebaseapp.com',
	projectId: 'simple-trello-dfdcf',
	storageBucket: 'simple-trello-dfdcf.appspot.com',
	messagingSenderId: '554554694917',
	appId: '1:554554694917:web:a54a51431d6c655353ab6c',
	measurementId: 'G-S8PD4F6W6X'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
