import { useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'react-bootstrap'
import AddOrUpdateBoardModal from './AddOrUpdateBoardModal'
import { Board } from '../../firebase/models'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

const AddBoard = () => {
	const defaultColor = '#' + (Math.random().toString(16) + '00000').slice(2, 8)
	const [showModal, setShowModal] = useState(false)
	const { setError } = useContext(AppContext)
	const [loading, setLoading] = useState(false)
	const [board] = useState(new Board())

	const handleCloseModal = () => setShowModal(false)
	const handleShowModal = () => setShowModal(true)
	const submitForm = async (data) => {
		try {
			setLoading(true)
			board.name = data.name
			board.bgColor = data.backgroundColor
			await board.save('add')
			handleCloseModal()
		} catch (error) {
			console.error('Create Board Error: ', error.message)
			setError({
				type: 'danger',
				title: 'Create Board Error',
				message: error.message
			})
		} finally {
			setLoading(false)
		}
	}
	return (
		<>
			<Card
				className="boards-card shadow-sm bg-secondary"
				onClick={handleShowModal}>
				<div className="boards-card__add">
					<FontAwesomeIcon icon={faPlus} className="text-light" />
				</div>
			</Card>
			{showModal && (
				<AddOrUpdateBoardModal
					show={showModal}
					handleClose={handleCloseModal}
					submitForm={submitForm}
					boardName={''}
					loading={loading}
					bgColor={defaultColor}
				/>
			)}
		</>
	)
}

export default AddBoard
