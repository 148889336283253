import { faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Draggable } from '@richardrout/react-smooth-dnd'
import { Card, Spinner } from 'react-bootstrap'
import BoardForm from './BoardForm'
import { BoardContext } from '../../views/Board'
import { AppContext } from '../../context/AppContext'

const BoardListItem = ({ item, listId, updateCardId, setUpdateCardId }) => {
	const { board } = useContext(BoardContext)
	const { setError } = useContext(AppContext)
	const [loading, setLoading] = useState(false)

	const updateCard = async (data) => {
		for (let list of board.lists) {
			if (list.id === listId) {
				for (let card of list.cards) {
					if (card.id === item.id) {
						card.data = data
						setLoading(true)
						try {
							await board.save()
							setUpdateCardId('')
						} catch (error) {
							setError({
								type: 'danger',
								title: 'Update Card Error.',
								message: 'Failed to update card.'
							})
						} finally {
							setLoading(false)
						}
					}
				}
			}
		}
	}

	const deleteCard = async () => {
		for (let list of board.lists) {
			if (list.id === listId) {
				list.cards = list.cards.filter((card) => card.id !== item.id)
				setLoading(true)
				try {
					await board.save()
				} catch (error) {
					console.log('Delete Card Error.', error)
					setError({
						type: 'danger',
						title: 'Delete Card Error.',
						message: 'Failed to delete card.'
					})
				} finally {
					setLoading(false)
				}
			}
		}
	}

	return (
		<Draggable
			key={item.id}
			// isDragDisabled={updateCardId === item.id}
		>
			<div className="list-item mb-2">
				{item.id !== updateCardId ? (
					<Card
						className="position-relative list-item__card fade-animation-parent shadow-sm"
						// style={{
						// 	backgroundColor: `${snapshot.isDragging ? '#faf6ec' : 'white'}`
						// }}
					>
						<Card.Body className="py-2 px-3">{item.data}</Card.Body>
						<div className="list-item__actions fade-animation bg-success bg-opacity-10 rounded-1">
							<FontAwesomeIcon
								icon={faPenAlt}
								className="list-item__actions__icon text-primary"
								// setTimeout make sure that BoardForm component close on clicking outside before we open new.
								onMouseDown={() => {
									setTimeout(() => {
										setUpdateCardId(item.id)
									}, 0)
								}}
							/>
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									<span className="visually-hidden">Loading...</span>
								</>
							) : (
								<FontAwesomeIcon
									icon={faTrashAlt}
									className="list-item__actions__icon text-danger"
									onClick={deleteCard}
								/>
							)}
						</div>
					</Card>
				) : (
					<BoardForm
						type="card"
						close={() => setUpdateCardId('')}
						btnText="Update Card"
						inputData={item.data}
						submit={updateCard}
						loading={loading}
					/>
				)}
			</div>
		</Draggable>
	)
}

export default BoardListItem
