import { Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { signIn } from '../firebase/auth'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { CleanFirebaseAuthErrorMessage } from '../utils'
import { serverTimestamp } from 'firebase/firestore'
import { User } from './../firebase/models'

const Login = () => {
	const { setError } = useContext(AppContext)
	const history = useHistory()
	const location = useLocation()
	const currUser = new User()

	let { from } = location.state || { from: { pathname: '/' } }

	const login = async (provider) => {
		const result = await signIn(provider).catch((error) => {
			console.error('user sign in: ', error.message)
			setError({
				type: 'danger',
				title: 'Login Error',
				message: CleanFirebaseAuthErrorMessage(error.code)
			})
		})

		if (result) {
			const userId = result.user.uid
			currUser.id = userId

			//create user document for new user.
			if (result._tokenResponse?.isNewUser) {
				currUser.name = result.user.displayName
				currUser.email = result.user.email
				currUser.photoURL = result.user.photoURL

				await currUser.save('add').catch((error) => {
					console.error('create user doc: ', error.message)
					setError({
						type: 'danger',
						title: 'Create User Error',
						message: error.message
					})
				})
			}

			//update last logged In time
			currUser.lastLoginAt = serverTimestamp()
			await currUser.save().catch((error) => {
				console.error('user last login update: ', error.message)
				setError({
					type: 'danger',
					title: 'User Last Login Error',
					message: error.message
				})
			})

			history.replace(from)
		}
	}

	return (
		<div className="login">
			<Card style={{ width: '18rem' }} className="text-center">
				<Card.Header>Sign In</Card.Header>
				<Card.Body>
					<Card.Title>Log in to use Simple Trello.</Card.Title>
					<Card.Text>Using social OAuth</Card.Text>
					<Button
						className="w-100 mb-3"
						variant="danger"
						onClick={() => login('google')}>
						<FontAwesomeIcon icon={faGoogle} size="lg" />
						<span className="ms-2">Google</span>
					</Button>
					<Button
						className="w-100"
						variant="dark"
						onClick={() => login('github')}>
						<FontAwesomeIcon icon={faGithub} size="lg" />
						<span className="ms-2">Github</span>
					</Button>
				</Card.Body>
			</Card>
		</div>
	)
}

export default Login
