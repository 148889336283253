import { useContext, useState, useReducer, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import { BoardContext } from '../../views/Board'
import AddCard from './AddCard'
import BoardListHeader from './BoardListHeader'
import BoardListItem from './BoardListItem'
import { Container, Draggable } from '@richardrout/react-smooth-dnd'
import AddList from './AddList'

const BoardList = () => {
	const { board } = useContext(BoardContext)
	const { setError } = useContext(AppContext)
	const [updateCardId, setUpdateCardId] = useState('')
	const [, forceUpdate] = useReducer((x) => x + 1, 0)

	const saveChange = async () => {
		try {
			await board.save()
		} catch (error) {
			console.log('Update List Title Error.', error)
			setError({
				type: 'danger',
				title: 'Update List Title Error.',
				message: 'Failed to update list title.'
			})
		}
	}

	const listDrop = async (result) => {
		const { removedIndex, addedIndex } = result
		if (removedIndex === null && addedIndex === null) return
		if (removedIndex === addedIndex) return

		let lists = board.lists

		//list to be moved
		const temp = lists[removedIndex]
		//delete the list from old position
		lists.splice(removedIndex, 1)
		//add the list to new position
		lists.splice(addedIndex, 0, temp)

		board.lists = lists
		forceUpdate()
		await saveChange()
	}

	const cardDrop = async (listId, result) => {
		const { removedIndex, addedIndex, payload } = result

		if (removedIndex === null && addedIndex === null) return
		if (removedIndex === addedIndex) return

		const lists = board.lists
		if (removedIndex !== null) {
			lists.forEach((list) => {
				if (listId === list.id) {
					//delete the card from old position
					list.cards.splice(removedIndex, 1)
				}
			})
			board.lists = lists
			forceUpdate()
		}

		if (addedIndex !== null) {
			lists.forEach((list) => {
				if (listId === list.id) {
					//add the card to new position
					list.cards.splice(addedIndex, 0, payload)
				}
			})
			board.lists = lists
			forceUpdate()
		}
		//since firebase use local data persistence, it will take one API request to update the changes.
		await saveChange()
	}

	/** bug fix- Native touch scroll is blocked after touch/scroll on the react-smooth-dnd #75 */
	useEffect(() => {
		const cleanClasses = () => {
			document.body.className = ''
		}
		document.addEventListener('touchend', cleanClasses, false)
		return () => {
			document.removeEventListener('touchend', cleanClasses, false)
		}
	}, [])

	return (
		<Container
			disableScrollOverlapDetection={true}
			style={{
				display: 'flex',
				gap: '1rem',
				flex: 1,
				overflowX: 'auto'
			}}
			onDrop={listDrop}
			orientation="horizontal"
			dragHandleSelector=".board-list__header"
		>
			{board.lists.map((list) => (
				<Draggable key={list.id}>
					<div className="board-list shadow-sm">
						<BoardListHeader title={list.title} id={list.id} />
						<div className="list-cards">
							<Container
								disableScrollOverlapDetection={true}
								style={{ minHeight: '0.5rem' }}
								onDrop={(e) => cardDrop(list.id, e)}
								groupName="col"
								getChildPayload={(index) => list.cards[index]}
							>
								{list.cards.map((card) => (
									<BoardListItem
										item={card}
										listId={list.id}
										key={card.id}
										board={board}
										updateCardId={updateCardId}
										setUpdateCardId={setUpdateCardId}
									/>
								))}
							</Container>
						</div>
						<AddCard listId={list.id} />
					</div>
				</Draggable>
			))}
			<AddList />
		</Container>
	)
}

export default BoardList
