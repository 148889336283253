import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CloseBtn = ({ onClose }) => {
	return (
		<div onClick={onClose} className="cursor-pointer">
			<FontAwesomeIcon icon={faTimes} size="lg" />
		</div>
	)
}

export default CloseBtn
